import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAPIService } from 'src/app/_api-services';
import { SearchType } from 'src/app/_enums';
import { User } from 'src/app/_models';
import { HeaderService } from 'src/app/_services';

@Component({
    selector: 'people-hub',
    templateUrl: './people-hub.component.html',
    styleUrl: './people-hub.component.scss'
})
export class PeopleHubComponent implements OnDestroy {
    private subscription: Subscription = new Subscription()
    
    searchType: SearchType = SearchType.User
    users: User[] | null = null
    defaultUsers: User[] | null = null
    
    constructor(
        private userAPIService: UserAPIService,
        private headerService: HeaderService
    ) {
        this.headerService.setHeaderState(true, false)
        
        this.subscription.add(
            this.userAPIService.getCurrentUserHiveContacts().subscribe({
                next: people => {
                    this.users = people
                    this.defaultUsers = this.users
                },
                error: err => {
                    console.error(err)
                }
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
    
    setPeople(users: User[] | null): void {
        if (users == null) {
            this.users = this.defaultUsers
            return
        }
        this.users = users
    }
}
