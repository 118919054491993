import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService, HeaderService } from 'src/app/_services';

@Component({
  selector: 'confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrl: './confirm-account.component.scss'
})
export class ConfirmAccountComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    
    success: string | null = null
    err: string | null = null
    
    constructor(
        private authService: AuthenticationService, 
        private route: ActivatedRoute,
        private router: Router,
        private headerService: HeaderService
    ) {
        this.headerService.setHeaderState(false)
        
        const email = this.route.snapshot.queryParamMap.get('email')
        const token = this.route.snapshot.queryParamMap.get('token')
        
        if(!email || !token) {
            this.err = 'Invalid email or token'
            return
        }
        
        this.subscriptions.add(
            this.authService.confirmAccount(email, token).subscribe({
                next: (res) => {
                    this.success = res['message']
                    
                    let to = setTimeout(() => {
                        this.router.navigate([''])    
                        clearTimeout(to)
                    }, 3000)
                    
                },
                error: (err) => {
                    console.log(err)
                    this.err = err.error
                }
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
