import { Component } from '@angular/core';

@Component({
  selector: 'business-tutorial',
  templateUrl: './business-tutorial.component.html',
  styleUrl: './business-tutorial.component.scss'
})
export class BusinessTutorialComponent {

}
