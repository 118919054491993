<div class="flex flex-column space-between flex-grow">
    <div class="flex-grow flex flex-column steps">
        <div class="step step-1" [style.transform]="getTranslateX(0)">
            <div class="tutorial-image">
                <img srcset="/assets/hive-tutorial-1.png 1x, /assets/hive-tutorial-1@2x.png" alt="create new hive">
            </div>
            <div class="tutorial">
                <h3>Create your first hive</h3>
                <p>
                    Gather your community with a Hive. Whetehr it's a small group of friends, family, a local farmers market, 
                    or a large community, Hives bring people together for any purpose you choose.
                </p>
            </div>
        </div>
        <div class="step step-2" [style.transform]="getTranslateX(1)">
            <div class="tutorial-image">
                <img srcset="/assets/hive-tutorial-2.png 1x, /assets/hive-tutorial-2@2x.png" alt="create new hive">
            </div>
            <div class="tutorial">
                <h3>Curate and Share Local Businesses</h3>
                <p>
                    As the queen bee, you can easily add local businesses to your community. Simply browse our directory 
                    and share businesses within your Hive. Members can discover these businesses and their offerings, and 
                    businesses will be notified, giving them the choice to join and engage with members in your Hive.
                </p>
            </div>
        </div>
        <div class="step step-3" [style.transform]="getTranslateX(2)">
            <div class="tutorial-image">
                <img srcset="/assets/hive-tutorial-3.png 1x, /assets/hive-tutorial-3@2x.png" alt="create new hive">
            </div>
            <div class="tutorial">
                <h3>Empower Members with easy discovery</h3>
                <p>
                    With HubHive, your members can easily find everything they need—from local businesses to upcoming 
                    events—right within the Hive. This seamless discovery process makes your community more connected, 
                    engaged, and empowered, eliminating the need for endless 'ISO' posts.
                </p>
            </div>
        </div>
    </div>
    
    <div class="step-markers">
        <span class="step-marker" [class.stepped]="step >= 0"></span>
        <span class="step-marker" [class.stepped]="step >= 1"></span>
        <span class="step-marker" [class.stepped]="step >= 2"></span>
    </div>
</div>