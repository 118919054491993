import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, OnChanges, Renderer2, SimpleChanges, TemplateRef } from '@angular/core';
import { TutorialService } from 'src/app/_services';



@Component({
  selector: 'tutorial-modal',
  templateUrl: './tutorial-modal.component.html',
  styleUrl: './tutorial-modal.component.scss'
})
export class TutorialModalComponent implements OnChanges, AfterViewChecked {
    isVisible: boolean = false
    content: TemplateRef<any> | null = null
    context: any = {}
    htmlBody: any
    activeStep: number = 0
    backActive: boolean = false
    nextButtonText: string = 'Next'
    
    requestSuccess: boolean = false
    requestFailure: boolean = false
    
    constructor(
        private el: ElementRef, 
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private tutorialService: TutorialService,
    ) {
    }
    
    @HostListener('window:resize')
    onWindowResize(): void {
        this.adjustModalHeight()
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes['isVisible'] && changes['isVisible'].currentValue) {
            this.adjustModalHeight()
        }
    }
    
    ngAfterViewInit(): void {
        this.adjustModalHeight()
        this.htmlBody = this.el.nativeElement.ownerDocument.body
    }
    
    ngAfterViewChecked(): void {
        if(this.isVisible) {
            this.adjustModalHeight()
        }
    }

    private adjustModalHeight(): void {
        const vh = window.innerHeight * 0.01
        let modal = this.el.nativeElement.querySelector('.modal')
        
        if(modal) {
            modal.style.setProperty('--vh', `${vh}px`)    
        }
    }
  
    open(content: TemplateRef<any>, context: any): void {
        this.content = content
        this.context = context
        this.isVisible = true
        this.cdr.detectChanges()
        
        this.makeBodyUnscrollable()
    }

    close(): void {
        console.log("closing")
        this.isVisible = false
        this.makeBodyScrollable()
    }
    
    skip(): void {
        this.tutorialService.doSkip()
    }
    
    makeBodyUnscrollable(): void {
        this.renderer.addClass(this.htmlBody, 'no-scroll')
    }
    
    makeBodyScrollable(): void {
        this.renderer.removeClass(this.htmlBody, 'no-scroll')
    }
    
    next(): void {
        this.tutorialService.doNext()
    }
    
    previous(): void {
        this.tutorialService.goBack()
    }
    
    setNextButtonText(text: string): void {
        this.nextButtonText = text
        this.cdr.detectChanges()
    }
}
