import {  NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HiveTutorialComponent } from './components/hive-tutorial/hive-tutorial.component';
import { BusinessTutorialComponent } from './components/business-tutorial/business-tutorial.component';
import { ProductTutorialComponent } from './components/product-tutorial/product-tutorial.component';
import { TutorialModalComponent } from './components/tutorial-modal/tutorial-modal.component';
import { DirectivesModule } from '../directives/directives.module';


@NgModule({
  declarations: [
    HiveTutorialComponent,
    BusinessTutorialComponent,
    ProductTutorialComponent,
    TutorialModalComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
  ],
  exports: [
    HiveTutorialComponent,
    BusinessTutorialComponent,
    ProductTutorialComponent,
    TutorialModalComponent,
  ]
})
export class TutorialsModule { }
