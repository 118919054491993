import { Component } from '@angular/core';

@Component({
  selector: 'product-tutorial',
  templateUrl: './product-tutorial.component.html',
  styleUrl: './product-tutorial.component.scss'
})
export class ProductTutorialComponent {

}
