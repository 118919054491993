import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { TutorialService } from 'src/app/_services';

@Component({
  selector: 'hive-tutorial',
  templateUrl: './hive-tutorial.component.html',
  styleUrl: './hive-tutorial.component.scss'
})
export class HiveTutorialComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    
    @Output() tutorialDone: EventEmitter<void> = new EventEmitter<void>()
    
    step: number = 0
    
    constructor(
        private tutorialService: TutorialService,
    ) {
        this.subscriptions.add(
            this.tutorialService.getNextAction().subscribe( () => {
                this.incrementStep()
            })    
        )
        
        this.subscriptions.add(
            this.tutorialService.getBackAction().subscribe( () => {
                this.decrementStep()
            })    
        )
        
        this.subscriptions.add(
            this.tutorialService.getSkipAction().subscribe( () => {
                this.tutorialDone.emit()
                this.tutorialService.close()
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    incrementStep(): void {
        if(this.step == 2) {
            this.tutorialDone.emit()
            this.tutorialService.close()
            return
        }
        
        this.step++
        this.tutorialService.activateBack()
        
        if(this.step == 2) {
            this.tutorialService.setNextButtonText('Get Started')
        }
    }
    
    decrementStep(): void {
        if(this.step <= 0) {
            return
        }
        
        this.step--
        this.tutorialService.setNextButtonText('Next')
        
        if(this.step <= 0) {
            this.tutorialService.deactivateBack()
        }
    }
    
    getTranslateX(index: number): string {
        const offset = (index - this.step) * 100; 
        return `translateX(${offset}%)`
    }
}
