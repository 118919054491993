<div class="modal-overlay" (click)="close()" *ngIf="isVisible">
    <div class="modal" stopPropagation>
        <div class="modal-controls">
            <span class="control" (click)="close()">
                <img src="/assets/icon-close.svg">
            </span>
            
            <span class="control" (click)="skip()">SKIP</span>
        </div>
        
        <div class="modal-content">
            <ng-container *ngIf="content">
                <ng-container *ngTemplateOutlet="content; context: { data: context }"></ng-container>
            </ng-container>
        </div>
        
        <div class="modal-footer">
            <span [class.active]="backActive" class="back" (click)="previous()">Back</span>
            <button class="btn btn-primary btn-rounded" (click)="next()">{{ nextButtonText }}</button>
        </div>
    </div>
</div>