import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BusinessAPIService } from 'src/app/_api-services';
import { HeaderService } from 'src/app/_services';
import { AccountNavigationService } from 'src/app/_services/account-navigation.service';

@Component({
  selector: 'confirm-business',
  templateUrl: './confirm-business.component.html',
  styleUrl: './confirm-business.component.scss'
})
export class ConfirmBusinessComponent {
    private subscriptions: Subscription = new Subscription()
    
    success: string | null = null
    err: string | null = null
    
    constructor(
        private businessAPIService: BusinessAPIService,
        private route: ActivatedRoute,
        private router: Router,
        private headerService: HeaderService,
        private accountNavigationService: AccountNavigationService,
    ) {
        this.headerService.setHeaderState(false)
        
        const id = this.route.snapshot.queryParamMap.get('id')
        const token = this.route.snapshot.queryParamMap.get('token')
        const email = this.route.snapshot.queryParamMap.get('email')
        const userId = this.route.snapshot.queryParamMap.get('user_id')
        
        if(!id || !token || !email || !userId) {
            this.err = 'Invalid id, email or token'
            return
        }
        
        this.subscriptions.add(
            this.businessAPIService.confirmAndClaimBusiness(id, token, email, userId).subscribe({
                next: (business) => {
                    if (business.id == "") {
                        this.success = "Bruh, your business is already confirmed"
                    } else {
                        this.accountNavigationService.addBusinessClaim(business)
                        this.success = "You have succesfully confirmed your business email."
                    }
                    
                    let to = setTimeout(() => {
                        this.router.navigate(['business', id, 'details'])    
                        clearTimeout(to)
                    }, 3000)
                    
                },
                error: (err) => {
                    console.log(err)
                    this.err = err.error
                }
            })
        )
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
