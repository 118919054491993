<div class="hub-container people-container">
    <div class="head-container">
        <div>
            <div class="flex space-between title">
                <h1>People</h1>
                <div class="flex btn btn-hub btn-skinny-rounded">
                    <img class="plus-icon" src="/assets/icon-plus.svg" alt="plus icon">
                    <p>Add</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page-content">
    <div class="container">
        <search [placeholderText]="'Search for a Person'" [searchType]="searchType" (recordOutput)="setPeople($event)"></search>
    </div>

    <section>
        <div class="container">
            <div class="people">
                <user-card *ngFor="let user of users" [user]="user"></user-card>
            </div>
        </div>
    </section>
</div>